import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate,NavLink } from "react-router-dom";

import { AiFillStar } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { db } from './Firebase/Firebase';
import Loader from './Loader';

import Navbar from './Navbar';
const Espresso = () => {
  const navigate = useNavigate();


const {id} = useParams()
const [product , setProduct] = useState({})

useEffect(() => {
 
getData()

}, [])



const getData = async() => {
await getDoc(doc(db, "Products", id)).then(docSnap => {
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      setProduct(docSnap.data())
    } else {
      console.log("No such document!");
    }
  })
}
const [count, setCount] = useState('0')
useEffect(() => {
  const timeout = setTimeout(()=> {
    setCount('1')
  }, 2000)

  return () => clearTimeout(timeout)
}, [count])

  return (
  // <>
  // </>
  <>
  {count === "0" ? (
    <>
    <Loader/>
    </>
  ): (
    <>
   <div    className="fontss">
      <div 
      style={{backgroundColor:'black'}}>
  {/* <Navbar/> */}
  
      </div>
      <section>
      <ul
          
          className="nav"
          style={{
            listStyle: "none",
            display: "flex",
            gap:'2rem',
            color:"black",
            justifyContent:'center'

            //   alignContent: "center",
            //   justifyContent: "center",
          }}
          >
          <li >
            <div className="aa ff" style={{ textDecoration:"none"}}>
              <Link  style={{textDecoration:"none",color:'black'}} to={"/"}>Home</Link>
            </div>
           
          </li>
          <li >
            <a className="aa ff" 
          //    style={}
            >
              <Link style={{color:'black'}} to={"/products"}>Products</Link>
            </a>
          </li>
          <li >
            <a className="aa ff">
              <Link style={{color:'black'}} to={"/about"}>About Us</Link>
            </a>
          </li>
        </ul>
      </section>
        <div className='cart55' >
          <div className='border' style={{display:'flex',justifyContent:'center'}}>
        <div className='left8'>
       <img src={product.image} alt=""/>
        </div>
  
        <div className='right8'>
            <div>
                <h1 className='heading-coffee flavour-coffeez'>{product.Name}</h1>
                <div className='cartt'>
  
                <div className='star1' style={{color:'gold',display:'flex'}}>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                </div>
                
                <p className='review' style={{textDecoration:'underline'}}>4.7 Ratings</p>
                <p className='review review2 ' style={{textDecoration:'underline'}}>7 Reviews</p>
  
                </div>
       
                <h1  style={{marginTop:'-10px'}} className='heading-coffee highlight'>Product Details</h1>
                <p className='cartpara'>{product.Description}</p>
        </div>
        {/* <div style={{display:'flex',gap:'2rem',alignItems: "center"}}>
          <h1 className='heading-coffee'>INGREDIENTS</h1>
          <h1 className='heading-c1' >Coffee, Chicory (20%)</h1>
  
        </div>
        <div style={{display:'flex',gap:'2rem',alignItems: "center"}}>
          <h1 className='heading-coffee' >ORIGIN</h1>
          <h1 className='heading-c1' >Pollibetta, Coorg</h1>
  
        </div>
        <div style={{display:'flex',gap:'2rem',alignItems: "center"}}>
          <h1 className='heading-coffee'>FLAVOUR</h1>
          <h1 className='heading-c1' >Deep,Roasty, Heavy Body</h1>
  
        </div> */}
        {/* <div style={{display:'flex',gap:'2rem',alignItems: "center"}}>
         
          <h1 className='heading-coffee'>ROAST</h1>
          <h1 className='heading-c1' >Medium Dark Roast </h1>
  
        </div> */}
  
        <div className='pricee'> 
          <h1 className='price-no' >₹{product.Price}</h1>
          <button  onClick={navigate('./cart')} class="button-4" role="button">
            Buy
            </button>
  
        </div>
        <div>
          
        </div>
        <div>
        </div>
     {/* <div className='drop'>
      <Dropdown/>
     </div>
   <div className='drop2'>
      <Dropdown2/>
   </div> */}
  
  
            </div>
        
          
        </div>
        </div>
        <h1></h1>
  
    </div>
  </>
  
  )}
    </>
  )
}

export default Espresso